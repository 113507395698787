<template>
    <div>
        <!-- Filters -->
        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col cols="2" md="2">
                        <label for="document_id">
                            Extract Type <small class="text-danger" v-if="serverErrors && serverErrors.document_id">{{ serverErrors.document_id[0] }}</small>
                        </label>
                        <b-form-select
                            id="document_id"
                            name="document_id"
                            size="sm"
                            v-model="document_id"
                        >
                            <b-form-select-option :value="null">Select...</b-form-select-option>
                            <b-form-select-option
                                v-for="document in documents"
                                :key="document.id"
                                :value="document.id"
                            >
                                {{ document.title }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                    <b-col
                        cols="6"
                        md="3"
                        class="mb-md-0 mb-2"
                        v-for="(toSelect, index) in selections"
                        :key="toSelect.level"
                    >
                        <label>{{ toSelect.label }}</label>
                        <b-form-select
                            size="sm"
                            v-model="toSelect.model"
                            @change="changeFilters(toSelect.model, index)"
                        >
                            <b-form-select-option :value="null">Select...</b-form-select-option>
                            <b-form-select-option
                                v-for="location in toSelect.locations"
                                :key="location.id"
                                :value="location"
                            >
                                {{ location.name.toUpperCase() }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                    <b-col cols="2" md="2" class="pt-2">
                        <b-button
                            variant="outline-success"
                            size="sm"
                            @click="generateReport()"
                        >
                            <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                            <span class="text-nowrap">Extract</span>
                            <feather-icon icon="DownloadCloudIcon" class="ml-1"/>
                        </b-button>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        <!-- ./Filters -->

    </div>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
        BSpinner, BBadge,
    } from 'bootstrap-vue'

    import store from '@/store'
    import { ref, onUnmounted, onMounted } from '@vue/composition-api'
    import vSelect from 'vue-select'
    import agronomyExtractsStoreModule from '@/views/cromis/reports/agronomy/agronomyExtractsStoreModule'
    import axios from '@axios'
    
    export default {
        props: {},
        components: {
            BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
            BForm, BFormGroup,
            BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
            BSpinner, BBadge,
            ValidationObserver, ValidationProvider,
        },
        directives: {},

        setup(props, context){
            const dataForm = ref(null)
            const myModal = ref(null)
            const saving = ref(false)
            const serverErrors = ref({
                document_id: null,
                number: null,
            })

            const documents = ref([])
            const document_id = ref(null)
            const number = ref(null)
            const selections = ref([])
            const levels = ref(null)
            const children = ref([])
            const location_id = ref(null)

            const CROMIS_STORE_MODULE_NAME = 'cromis-agronomy-extracts'

            // Register module
            if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, agronomyExtractsStoreModule)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
            })

            onMounted(async () => {
                getInnerMenus(JSON.parse(localStorage.getItem('userData')).menus, context.root.$router.currentRoute.name)

                await store.dispatch('cromis-agronomy-extracts/levels')
                        .then(response => {
                            levels.value = response.data.levels[0]

                            selections.value.push({
                                level: levels.value.name.toLowerCase(),
                                model: null,
                                label: levels.value.name,
                                child: levels.value.children.length > 0 ? levels.value.children[0].name.toLowerCase() : null,
                                locations: [],
                            })

                            let list = levels.value.children

                            while(list.length > 0){
                                children.value.push(list[0])
                                if(list[0].children > 0){
                                    selections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: list[0].children[0].name.toLowerCase(), locations: [] })
                                }
                                else{
                                    selections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: null, locations: [] })
                                }

                                list = list[0].children
                            }
                        })
                        .catch(error => {
                            console.log('got error', error)
                        })

                await store.dispatch('cromis-agronomy-extracts/locations')
                            .then(response => {
                                populateSelections(response.data.locations)
                            })
                            .catch(error => {
                                console.log('got error', error)
                            })
            })

            const getInnerMenus = (menus, currentMenu) => {
                menus.map((menu) => {
                    if(menu.children.length > 0)
                        getInnerMenus(menu.children, currentMenu)

                    if(menu.webRoute && menu.webRoute.localeCompare(currentMenu) === 0)
                        documents.value = menu.children

                })
            }

            const populateSelections = (locations) => {
                for(let i = 0; i < locations.length; i++){
                    for(let x = 0; x < selections.value.length; x++){
                        if(locations[i].level.name.toLowerCase() === selections.value[x].level){
                            selections.value[x].locations.push(locations[i])
                        }
                    }
                }
            }

            const changeFilters = async (location, index) => {
                if(!location){
                    for(let i = index + 1; i < selections.value.length; i++){
                        selections.value[i].locations.splice(0)
                        selections.value[i].model = null
                    }

                    location_id.value = null
                }
                else{
                    if(selections.value[index + 1]){
                        selections.value[index + 1].locations.splice(0)
                    }

                    location.children.map((child) => {
                        selections.value[index + 1].locations.push(child)
                    })

                    if(location.children.length > 0){
                        selections.value[index + 1].model = null
                    }

                    location_id.value = location.id
                }
            }

            const generateReport = async () => {
                let hasError = false
                serverErrors.value = {
                    document_id: null,
                }

                if(!document_id.value){
                    hasError = true
                    serverErrors.value.document_id = ['Select extract type']
                }

                if(!hasError){
                    saving.value = true

                    // await store.dispatch('cromis-attachment/generate', { documents: [document_id.value], number: number.value, recipients: [] })
                    //     .then(response => {
                    //         saving.value = false
                            
                    //     })
                    //     .catch(error => {
                    //         console.log('got error', error)
                    //     })
                    window.open(`${ axios.defaults.baseURL }/reports/agronomy?report_id=${ document_id.value }&location_id=${ location_id.value }`, '_blank')
                    saving.value = false
                }
            }

            return {
                dataForm,
                saving,
                serverErrors,
                documents,
                document_id,
                number,
                generateReport,
                selections,
                levels,
                children,
                changeFilters,
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
